[data-v-1dc89995] .el-tree {
  height: 60vh !important;
  max-height: 60vh !important;
  overflow: scroll;
}
[data-v-1dc89995] .avue-crud .el-table {
  height: 55vh !important;
  max-height: 55vh !important;
}
.eq-status-box[data-v-1dc89995] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 40px;
}
.eq-status[data-v-1dc89995] {
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
}
.eq-status-1[data-v-1dc89995] {
  border: 1px solid #67c23a;
  color: #67c23a;
  background-color: #EEF8E8;
}
.eq-status-2[data-v-1dc89995] {
  border: 1px solid #606266;
  color: #606266;
  background-color: #F2F2F3;
}
.eq-status-3[data-v-1dc89995] {
  border: 1px solid #E49D3F;
  color: #E49D3F;
  background-color: #FDF5E9;
}
.eq-status-4[data-v-1dc89995] {
  border: 1px solid #F69090;
  color: #F69090;
  background-color: #FEEEEE;
}